import MuxPlayer from "@mux/mux-player-react"

const StreamIframe = ({ hide, link, orderId, handleClick }) => {
  return (
    <section
      className="main-carousel"
      id="home-streamiframe"
      style={{ position: "relative" }}
    >
      {orderId != null && orderId !== -1 && orderId !== "" ? (
        // <iframe
        //   src={link}
        //   allowFullScreen
        //   frameBorder="0"
        //   className="video-player"
        //   style={{
        //     height: "100%",
        //   }}
        //   title="live-stream"
        // ></iframe>
        <MuxPlayer
          autoplay="muted"
          streamType="live"
          playbackId={link ?? ""}
          className="mux-player video-player"
        />
      ) : (
        <>
          {hide && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: "100",
              }}
            >
              <h3 style={{ textAlign: "center", fontSize: "32px" }}>
                Preview complete! Ready to experience more? Purchase now to
                start streaming.
              </h3>
              <button
                className="ordernow-btn"
                style={{ marginInline: "auto", display: "block" }}
                onClick={handleClick}
              >
                Order Now
              </button>
            </div>
          )}
          {/* <iframe
            src={hide ? "" : link}
            allowFullScreen
            frameBorder="0"
            className="video-player"
            style={{
              height: "100%",
            }}
            title="live-stream"
          ></iframe> */}
          <MuxPlayer
            autoplay="muted"
            streamType="live"
            playbackId={hide ? "" : link}
            className="mux-player video-player"
          />
        </>
      )}
    </section>
  )
}

export default StreamIframe
