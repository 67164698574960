import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom'
import AppConfig from '../../config/app.config';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})



export class Brand extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultObject: props.resultObject,
            id: props.id,
            image: AppConfig.API.MANU_IMAGE + props.id + "&random=" + Date.now(),
            loading: true
        };
    }

    handleImageLoaded = () => {
        this.setState({ loading: false });
    };
    handleImageError = () => {
        this.setState({ loading: false });
    };


    render() {
        return (
            <div className=" block-images position-relative">
                <div className="img-box">
                    <Backdrop style={{ zIndex: 9999999, color: "#fff" }} open={this.state.loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <img
                        src={this.state.resultObject.webUrl}
                        srcSet={`${this.state.resultObject.webUrl}?w=320 320w, ${this.state.resultObject.webUrl}?w=640 640w, ${this.state.resultObject.webUrl}?w=1280 1280w`}
                        sizes="(max-width: 640px) 320px, (max-width: 1280px) 640px, 1280px"
                        className="img-fluid d-brand-img"
                        alt={this.state.resultObject.name}
                        loading="lazy"
                        onLoad={this.handleImageLoaded}
                        onError={this.handleImageError}
                    />
                </div>
                <Link target="_blank" to={{ pathname: this.state.resultObject.webUrl }} >
                    <div className="block-description block-social-info">
                        <h6 className="iq-title">{this.state.resultObject.name} </h6>
                    </div>
                </Link>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brand)