const URL = "https://www.myciright.com/Ciright"

const AppConfig = {
  API: {
    ENDPOINT: URL + "/api",
    USER_PROFILE:
      URL +
      "/ajaxCall-photo.htm?flag=employeePhoto&compress=0&colorCode=veuit&id=",
    MANU_IMAGE:
      URL + "/ajaxCall-photo.htm?flag=veuitNetworkManufactLogo&compress=0&id=",

    SUBSCRIPTION_ID: 8263180,
    VERTICAL_ID: 2453,
    APP_ID: 2838,
    SPHERE_TYPE_URL: "veuit-live.htm",
    SPHERE_TYPE_ID: 2,
    BASE_PATH: null,
    CIRIGHT_WORLD_VERTICAL_ID: 18,
    SPONSOR_SPHERE_TYPE_URL: "zaza-sponsership",
    APP_ZIPCODE: "08401",
    PAYMENT_MODE: 1,
    EVENT_ID: 2861560,
    IS_ANNOUNCEMENT: 0,
    MUX_SUBSCRIPTION_ID: 8932998,
  },
}

export default AppConfig
