import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { bindActionCreators } from "redux";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import PurchaseOrderStatusModel from "../../components/model/purchase-order-status-model";
import { ReactComponent as Returncart } from "../../assets/images/returncart.svg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  selectCart,
  selectMasterEvent,
  selectemployeeData,
} from "../../store/home/home.selectors";
import { updateCart, updateMasterEvent } from "../../store/home/home.actions";
import { setSigninRedirectPath } from "../../store/home/home.actions";
import CreateAccount from "../../components/create-account/create-account";
import StripePayment from "../../components/stripe-payment/stripe-payment";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";

// const removeExtraSpace = (s) => s.trim().replace(/\s/g, "");
// const rule = new RegExp(/^[a-zA-Z0-9]{3,40}$/);
// var pattern = new RegExp(/^[0-9\b]+$/);

const mapStateToProps = (state) => {
  return {
    cart: selectCart(state),
    masterEvent: selectMasterEvent(state),
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { updateCart, updateMasterEvent, setSigninRedirectPath },
    dispatch
  ),
});

export class CheckoutTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeData: null,
      checkingUpdate: false,
      stateMap: [],
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      countryId: "",
      stateId: -1,
      city: "",
      zipcode: "",
      phone: "",
      employeeAddressId: null,
      cardNumber: "",
      cardExpiration: "",
      cardHolderName: "",
      cardCVC: "",
      activeTabName: "first",
      masterEvent: null,
      taxPercent: 0,
      convenienceFee: 0,
      subTotal: 0,
      totalConvenienceFeesQuantity: 0,
      totalConvenienceFees: 0,
      eventList: [],
      isSuccessModalVisible: false,
      isTicketPurchasePolicyAgreed: false,
      orderId: "",
      clientSecret: "",
      clientPublicKey: "",
      totalQuantity: 1,
      totalSubTotal: 0,
      totalAmount: 0,
      cardMap: [],
      cardId: "",
      eventImage: "",
      showOrderConfirmModal: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("eventData") != null) {
      const eventData = await JSON.parse(localStorage.getItem("eventData"));
      this.setState({ eventImage: eventData.eventImage });
      const eventList = [
        {
          eventId: eventData.eventId,
          eventDate: eventData.eventdate,
          eventName: eventData.eventName,
          price:
            eventData.price != null && !isNaN(eventData.price)
              ? eventData.price
              : 9.99,
          quantity: 1,
        },
      ];
      await this.setState({
        eventList,
        subTotal:
          eventData.price != null && !isNaN(eventData.price)
            ? eventData.price
            : 9.99,
        convenienceFee:
          eventData.convenienceFee != null && !isNaN(eventData.convenienceFee)
            ? eventData.convenienceFee
            : 0,
        totalConvenienceFeesQuantity: 1,
        totalConvenienceFees:
          eventData.convenienceFee != null && !isNaN(eventData.convenienceFee)
            ? 1 * eventData.convenienceFee
            : 0,
        totalQuantity: 1,
        totalSubTotal:
          eventData.price != null && !isNaN(eventData.price)
            ? eventData.price
            : 9.99,
      });

      var employeeData = this.props.employeeData;
      await this.setState({
        employeeData: employeeData,
        activeTabName: employeeData?.employeeId != null ? "third" : "first",
      });

      //await this.getTaxPercent();

      if (employeeData?.employeeId != null && employeeData?.employeeId !== -1) {
        await this.buyEventTicket();
      } else {
        this.setState({ isLoading: 0 });
      }
    } else {
      NotificationManager.warning("Something Went Wrong!!", "", 2000);
      this.props.history.push("/");
    }
    // this.setState({
    //   activeTabName: "third",
    //   clientSecret:
    //     "pi_3PqAA5Ge0Sbpt8DV0VxMituF_secret_5Xs9AfGRiNbD3iyspQwGZp0Th",
    //   clientPublicKey:
    //     "pk_test_51PnyHZGe0Sbpt8DVkId346MCWYy38a5Cwqg9jNGv9gFQMWOgGKOIKVIzXahxiE11nOXDmWum0pz2pssuVvUBu3pL00wiMX86pA",
    //   orderId: "2229640",
    //   totalAmount: 2500,
    // });

    //await this.getAddressList();
    //await this.getStateList();

    //if (this.props.employeeData?.employeeId != null) {
    // } else {
    //   this.props.setSigninRedirectPath({
    //     signinRedirectPath: "/checkout-ticket",
    //   });
    //   this.props.history.push("/authentication/my-account");
    // }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.employeeData !== prevProps.employeeData) {
      this.setState({
        employeeData: this.props.employeeData,
        firstName: this.props.employeeData?.firstName ?? "",
        lastName: this.props.employeeData?.lastName ?? "",
        phone:
          this.props.employeeData?.phone != null
            ? this.formatPhone(this.props.employeeData?.phone)
            : "",
        email: this.props.employeeData?.email,
      });
    }
  }

  getAddressList = async () => {
    try {
      const employeeId = this.props.employeeData?.employeeId;
      if (employeeId != null) {
        var employeeData = this.props.employeeData;
        const res = await UserService.getAddressList(employeeId);
        if (res != null && res.status && res.data != null) {
          const { data } = res;
          if (data != null && data.length > 0) {
            const addressData = data[0];
            this.setState({
              firstName:
                addressData.addressName?.split(" ")[0] ??
                employeeData?.firstName ??
                "",
              lastName:
                addressData.addressName?.split(" ")[1] ??
                employeeData?.lastName ??
                "",
              address: addressData.address ?? "",
              city: addressData.city ?? "",
              stateId: addressData.stateId ?? "",
              zipcode: addressData.zipCode ?? "",
              email: employeeData?.email ?? "",
              phone: this.formatPhone(
                addressData.phone != null && addressData.phone !== ""
                  ? addressData.phone
                  : employeeData?.phone ?? ""
              ),
              employeeAddressId: addressData.employeeAddressId,
            });
          }
        } else {
          this.setState({
            firstName: employeeData?.firstName ?? "",
            lastName: employeeData?.lastName ?? "",
            phone: this.formatPhone(employeeData?.phone ?? ""),
            email: employeeData?.email ?? "",
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getTaxPercent = async () => {
    try {
      const res = await UserService.getTaxByZipcode(AppConfig.API.APP_ZIPCODE);
      if (res.error != null) {
        console.log(res.error?.response?.data?.message);
      } else {
        if (res["status"]) {
          this.setState({ taxPercent: res["data"][0].taxPercent });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getStateList = async () => {
    try {
      const res = await UserService.getStateList(1);
      if (res.error != null) {
        // console.log(res.error?.response?.data?.message);
      } else {
        if (res["status"]) {
          this.setState({ stateMap: res["data"] });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  updateTab = (value) => {
    // const phone = this.state.phone.split("-").join("");
    if (value === "third") {
      // if (this.state.firstName.trim() === "") {
      //   NotificationManager.warning("Please Enter Fist Name.", "", 2000);
      // } else if (!rule.test(this.state.firstName)) {
      //   NotificationManager.warning(
      //     "First Name must contains at list two letter with only characters, not allow numaric value.",
      //     "",
      //     2000
      //   );
      // } else if (this.state.lastName.trim() === "") {
      //   NotificationManager.warning("Please Enter Last Name.", "", 2000);
      // } else if (!rule.test(this.state.lastName)) {
      //   NotificationManager.warning(
      //     "Last Name must contains at list two letter with only characters, not allow numaric value.",
      //     "",
      //     2000
      //   );
      // } else if (this.state.address.trim() === "") {
      //   NotificationManager.warning("Please Enter Address.", "", 2000);
      // } else if (this.state.stateId === "" || this.state.stateId === -1) {
      //   NotificationManager.warning("Please Enter State.", "", 2000);
      // } else if (this.state.city.trim() === "") {
      //   NotificationManager.warning("Please Enter City.", "", 2000);
      // } else if (this.state.zipcode.trim() === "") {
      //   NotificationManager.warning("Please Enter ZipCode.", "", 2000);
      // } else if (!pattern.test(phone)) {
      //   NotificationManager.warning("Please Enter Valid Phone.", "", 2000);
      // } else if (phone.length < 10 || phone.length > 13) {
      //   NotificationManager.warning("Please Enter Valid Phone.", "", 2000);
      // } else if (this.state.email.trim() === "") {
      //   NotificationManager.warning("Please Enter Email.", "", 2000);
      // } else if (!validator.isEmail(this.state.email)) {
      //   NotificationManager.warning("Please Enter Valid Email.", "", 2000);
      // } else {
      //   if (AppConfig.API.PAYMENT_MODE === 1) {
      //     this.buyEventTicket();
      //   } else if (AppConfig.API.PAYMENT_MODE === 2) {
      //     this.setState({ activeTabName: value });
      //   } else {
      //     NotificationManager.warning(
      //       "Please Define Your Payment Mode",
      //       "",
      //       2000
      //     );
      //   }
      // }

      if (AppConfig.API.PAYMENT_MODE === 1) {
        this.buyEventTicket();
      } else if (AppConfig.API.PAYMENT_MODE === 2) {
        this.setState({ activeTabName: value });
      } else {
        NotificationManager.warning(
          "Please Define Your Payment Mode",
          "",
          2000
        );
      }
    } else if (value === "second") {
      this.setState({
        activeTabName: value,
        clientPublicKey: null,
        clientSecret: null,
        orderId: null,
        totalAmount: 0,
      });
    }
  };

  buyEventTicket = async () => {
    try {
      if (this.props.employeeData?.employeeId != null) {
        this.setState({ isLoading: 1 });
        let eventList = this.state.eventList;
        var referralCode = sessionStorage.getItem("referralCode");
        referralCode =
          referralCode != null && referralCode !== ""
            ? referralCode
            : "xmve6633vb";

        if (eventList !== null && eventList.length > 0) {
          // const phone = this.state.phone.split("-").join("");
          const payload = {
            subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
            verticalId: AppConfig.API.VERTICAL_ID,
            appId: AppConfig.API.APP_ID,
            loginEmployeeId: this.props.employeeData?.employeeId,
            tax: (
              ((this.state.totalSubTotal +
                this.state.convenienceFee * this.state.totalQuantity) *
                this.state.taxPercent) /
              100
            ).toFixed(2),
            totalQuantity: this.state.totalQuantity,
            convenienceFee: (
              this.state.convenienceFee * this.state.totalQuantity
            ).toFixed(2),
            orderTotal: (
              this.state.subTotal +
              this.state.totalConvenienceFees +
              ((this.state.subTotal + this.state.totalConvenienceFees) *
                this.state.taxPercent) /
                100
            ).toFixed(2),
            events: eventList,
            paymentMode: AppConfig.API.PAYMENT_MODE,
            referenceCode: referralCode,
            employeeAddressId: this.state.employeeAddressId,
          };

          const res = await UserService.buyEventTicket(payload);
          if (res.error != null) {
            NotificationManager.error(
              res.error.response.data.message ??
                "You can't buy streaming, please try again.",
              "",
              2000
            );
            this.setState({ isLoading: 0 });
            this.props.history.push("/");
          } else {
            if (res["status"]) {
              var clientPublicKey =
                res["data"]?.paymentGatewayInfo?.clientPublicKey;
              this.setState({
                activeTabName: "third",
                clientSecret: res["data"]?.paymentGatewayInfo?.clientSecret,
                clientPublicKey: clientPublicKey,
                orderId: res["data"].orderId,
                totalAmount: res["data"]?.paymentGatewayInfo?.orderTotalAmount,
                isLoading: 0,
              });
            } else {
              NotificationManager.warning(res["message"], "", 2000);
              this.setState({ isLoading: 0 });
              this.props.history.push("/");
            }
          }
        } else {
          NotificationManager.error(
            "You can't buy streaming, please try again.",
            "",
            2000
          );
          this.setState({ isLoading: 0 });
          this.props.history.push("/");
        }
      } else {
        NotificationManager.error("please Sign In or Sign Up.", "", 2000);
        this.props.setSigninRedirectPath({
          signinRedirectPath: "/checkout-ticket",
        });

        this.props.history.push("/authentication/my-account");
      }
    } catch (err) {
      NotificationManager.error(
        "You can't buy streaming, please try again.",
        "",
        2000
      );
      this.props.history.push("/");
      this.setState({ isLoading: 0 });
    }
  };

  removeCart = () => {
    const tempMasterEvent = JSON.parse(JSON.stringify(this.state.masterEvent));
    tempMasterEvent.quantity = 0;
    if (
      tempMasterEvent.events != null &&
      tempMasterEvent.events !== null &&
      tempMasterEvent.events.length > 0
    ) {
      tempMasterEvent.events.forEach((event, index) => {
        tempMasterEvent.events[index].quantity = 0;
      });
    }
    this.props.updateMasterEvent({
      masterEvent: tempMasterEvent,
    });
    localStorage.setItem("masterEvent", JSON.stringify(tempMasterEvent));
  };

  redirectPage = async () => {
    this.setState({
      employeeId: this.props.employeeData?.employeeId,
      activeTabName:
        this.props.employeeData?.employeeId != null ? "third" : "first",
    });

    await this.buyEventTicket();
  };

  closeSuccessModal = (isPaymentSuccess) => {
    this.removeCart();
    const orderId = this.state.orderId;
    this.setState({ isSuccessModalVisible: false, orderId: null });
    this.props.history.push(
      isPaymentSuccess === true ? "/payment-confirmation" : "",
      { orderId: isPaymentSuccess === true ? orderId : undefined }
    );
  };

  formatPhone = (value) => {
    const phoneNumber = value.replace(/\D/g, "");
    let formatted = "";
    if (phoneNumber.length > 3) {
      formatted += phoneNumber.substr(0, 3) + "-";
      if (phoneNumber.length > 6) {
        formatted += phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
      } else {
        formatted += phoneNumber.substr(3);
      }
    } else {
      formatted = phoneNumber;
    }
    return formatted.slice(0, 13);
  };

  render() {
    return (
      <>
        {this.state.isLoading === 1 && <Loader />}

        <div className="checkout-white-bg">
          <div className="portal-accountcreation">
            <div className="portal-accountcreation-left">
              <Tabs
                activeKey={this.state.activeTabName}
                onSelect={(key) => {
                  this.updateTab(key);
                }}
              >
                <Tab
                  eventKey="first"
                  title={<p>Account INFO</p>}
                  className="tab-contents-set"
                  disabled={this.state.activeTabName === "first" ? false : true}
                >
                  <CreateAccount redirectPage={this.redirectPage} />
                </Tab>
                {/* <Tab
                  eventKey="second"
                  title={
                    <p>
                      <span>2</span>Billing info
                    </p>
                  }
                  className="tab-contents-set"
                  disabled={
                    this.state.activeTabName === "second" ||
                    this.state.activeTabName === "third"
                      ? false
                      : true
                  }
                >
                  <div className="create-acoount-content" id="Cust-Info">
                    <p id="cust-text">Customer Information</p>
                    <h1>Enter Billing Information</h1>
                    <div className="createacoount-form">
                      <Form.Group>
                        <label className="label">First name</label>
                        <Form.Control
                          type="text"
                          className="mb-0 create-input"
                          value={this.state.firstName}
                          onChange={(e) =>
                            this.setState({
                              firstName: removeExtraSpace(e.target.value),
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          required
                        />
                      </Form.Group>
                      <Form.Group>
                        <label className="label">LAST NAME</label>
                        <Form.Control
                          type="text"
                          className="mb-0 create-input"
                          maxLength="20"
                          value={this.state.lastName}
                          onChange={(e) =>
                            this.setState({
                              lastName: removeExtraSpace(e.target.value),
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="createacoount-form">
                      <Form.Group id="address-form">
                        <label className="label">ADDRESS</label>
                        <Form.Control
                          type="text"
                          className="mb-0 create-address"
                          maxLength="20"
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="createacoount-form" id="mob-stateview">
                      <Form.Group className="web-state">
                        <label className="label">State</label>
                        <Form.Control
                          as="select"
                          className="mb-0 create-input"
                          value={this.state.stateId}
                          onChange={(e) =>
                            this.setState({ stateId: e.target.value })
                          }
                          id="select-set"
                        >
                          <option>Select your State</option>
                          {this.state.stateMap.map((res, index) => {
                            return (
                              <option key={index} value={res.key}>
                                {res.value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group className="mob-state">
                        <label className="label">State</label>
                        <Form.Control
                          as="select"
                          className="mb-0 create-input"
                          id="select-set"
                          value={this.state.stateId}
                          onChange={(e) =>
                            this.setState({ stateId: e.target.value })
                          }
                        >
                          <option></option>
                          {this.state.stateMap.map((res, index) => {
                            return (
                              <option key={index} value={res.key}>
                                {res.value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label className="label">City</label>
                        <Form.Control
                          type="text"
                          className="mb-0 create-input"
                          maxLength="20"
                          value={this.state.city}
                          onChange={(e) =>
                            this.setState({ city: e.target.value })
                          }
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="createacoount-form" id="mob-stateview">
                      <Form.Group>
                        <label className="label">ZIP CODE</label>
                        <div id="zipcode">
                          <Form.Control
                            type="text"
                            className="mb-0 create-input" 
                            value={this.state.zipcode}
                            onChange={(e) => {
                              const numberRule = new RegExp(/^[0-9]*$/);
                              if (numberRule.test(e.target.value)) {
                                this.setState({
                                  zipcode: removeExtraSpace(e.target.value),
                                });
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            required
                          />
                        
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <label className="label">PHONE</label>
                        <Form.Control
                          type="text"
                          className="mb-0 create-input"
                          maxLength="13"
                          value={this.state.phone}
                          onChange={(e) => {
                            this.setState({
                              phone: this.formatPhone(e.target.value),
                            });
                          }}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="createacoount-form">
                      <Form.Group>
                        <label className="label">email address</label>
                        <Form.Control
                          type="email"
                          className="mb-0 create-input"
                          id="txtBillingEmail"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({
                              email: removeExtraSpace(e.target.value),
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="stripe-payment-info">
                      <div className="stripe-payment-subtotal">
                        <h1>Subtotal</h1>
                        <h2>${this.state.subTotal.toFixed(2)}</h2>
                      </div>
                      <div className="stripe-payment-subtotal">
                        <h1>Processing Fees</h1>
                        <h2>${this.state.totalConvenienceFees.toFixed(2)} </h2>
                      </div>
                      <div className="stripe-payment-subtotal">
                        <h1>Tax</h1>
                        <h2>
                          $
                          {(
                            ((this.state.subTotal +
                              this.state.totalConvenienceFees) *
                              this.state.taxPercent) /
                            100
                          ).toFixed(2)}
                        </h2>
                      </div>
                      <div className="stripe-payment-total">
                        <h1>Total</h1>
                        <h2>
                          $
                          {(
                            this.state.subTotal +
                            this.state.totalConvenienceFees +
                            ((this.state.subTotal +
                              this.state.totalConvenienceFees) *
                              this.state.taxPercent) /
                              100
                          ).toFixed(2)}
                        </h2>
                      </div>
                    </div>
                    <div className="checkout-btn">
                      <div className="checkout-returncart">
                        <Returncart className="arrow-img" />
                      </div>
                      <button
                        className="Create-Account"
                        onClick={() => this.updateTab("third")}
                      >
                        Continue to Payment
                      </button>
                    </div>
                  </div>
                </Tab> */}
                <Tab
                  eventKey="third"
                  title={<p>PAYMENT Info</p>}
                  className="tab-contents-set1"
                  disabled={this.state.activeTabName === "third" ? false : true}
                >
                  <div
                    className="create-acoount-content"
                    id="payment-selection"
                  >
                    <p>Payment</p>

                    <div defaultActiveKey="1" id="cardpay-acc">
                      <Card id="checkout-cardpay">
                        <div eventKey="1">
                          <Card.Body>
                            {this.state.clientSecret != null &&
                            this.state.clientSecret !== "" &&
                            this.state.clientPublicKey != null &&
                            this.state.clientPublicKey !== "" ? (
                              <StripePayment
                                clientSecret={this.state.clientSecret}
                                clientPublicKey={this.state.clientPublicKey}
                                orderId={this.state.orderId}
                                returnUrl={
                                  `${window.location.origin}/stripe-payment-confirmation/` +
                                  this.state.orderId
                                }
                                subTotal={this.state.subTotal}
                                totalConvenienceFees={
                                  this.state.totalConvenienceFees
                                }
                                taxPercent={this.state.taxPercent}
                              />
                            ) : (
                              ""
                            )}
                          </Card.Body>
                        </div>
                      </Card>
                    </div>
                    <div className="checkout-btn">
                      <div
                        className="checkout-returncart"
                        //onClick={() => this.updateTab("second")}
                      >
                        <Link to="/buy-ticket">
                          <Returncart className="arrow-img" />
                        </Link>

                        <p id="back-cart-mobile"></p>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div
              className="portal-accountcreation-right"
              style={{ paddingBottom: "10px" }}
            >
              <div className="event-checkout-page">
                {this.state.eventImage != null &&
                this.state.eventImage !== "" ? (
                  <img src={this.state.eventImage} alt="Event img" />
                ) : (
                  ""
                )}
              </div>
              <div className="artist-info">
                {AppConfig.API.IS_ANNOUNCEMENT !== 0 ? (
                  <p className="artist-name">MJ</p>
                ) : (
                  <p className="artist-name">MJ</p>
                )}
                <p className="concert-location">Live From Las Vegas</p>
                <p className="concert-location">
                  Live Stream Ticket{" "}
                  {/* <p
                    style={{
                      fontWeight: 200,
                      display: "inline",
                    }}
                  >
                    (includes Replay)
                  </p> */}
                </p>
              </div>
              <div className="accountcreate-promo">
                {/* <Accordion>
                <Card>
                  <Card.Header>
                    <ContextAwareAccordionToggle as={Button} variant="link" eventKey="2">
                      <div className="tickets-toggle">
                        <p>Have a promo code?</p>
                        <Down />
                      </div>
                    </ContextAwareAccordionToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>2457678</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion> */}

                <div className="accountcreate-subtotal">
                  <h1>Subtotal</h1>
                  <h2>${this.state.subTotal.toFixed(2)}</h2>
                </div>
                <div className="accountcreate-subtotal">
                  <h1>Processing Fees</h1>
                  <h2>${this.state.totalConvenienceFees.toFixed(2)} </h2>
                </div>
                <div className="accountcreate-subtotal">
                  <h1>Tax</h1>
                  <h2>
                    $
                    {(
                      ((this.state.subTotal + this.state.totalConvenienceFees) *
                        this.state.taxPercent) /
                      100
                    ).toFixed(2)}
                  </h2>
                </div>
                <div className="accountcreate-total">
                  <h1>Total</h1>
                  <h2>
                    $
                    {(
                      this.state.subTotal +
                      this.state.totalConvenienceFees +
                      ((this.state.subTotal + this.state.totalConvenienceFees) *
                        this.state.taxPercent) /
                        100
                    ).toFixed(2)}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.isSuccessModalVisible && (
          <PurchaseOrderStatusModel
            isShow={this.state.isSuccessModalVisible}
            orderId={this.state.orderId}
            closeSuccessModal={this.closeSuccessModal}
          />
        )}
        {/* <OrderConfirmationModal
          show={this.state.showOrderConfirmModal}
          onHide={() => this.setState({ showOrderConfirmModal: false })}
        /> */}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTicket);
