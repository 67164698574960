import React from "react";
import { Link } from "react-router-dom";
import AppConfig from "../../config/app.config";
import {
  selectemployeeData,
  selecteventData,
} from "../../store/home/home.selectors";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Affiliates from "../../views/Affiliates/Affiliates";
const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
    eventData: selecteventData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class FooterManu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: "",
      employeeData: props.employeeData,
      isAffiliateModalOpen: false,
      eventData: null,
      displayText: "",
    };
  }

  async componentDidMount() {
    const pathArray = window.location.pathname.split("/");
    if (pathArray.length >= 2) {
      this.setState({ pathName: pathArray[1] });
    }

    // if (localStorage.getItem("eventData") != null) {
    //   const eventData = await JSON.parse(localStorage.getItem("eventData"));
    //   this.setState({ eventData: eventData });

    // }
  }

  async componentDidUpdate(prevProps, prevState) {
    const pathArray = window.location.pathname.split("/");
    if (pathArray.length >= 2 && this.state.pathName !== pathArray[1]) {
      this.setState({ pathName: pathArray[1] });
    }

    if (this.props.employeeData !== prevProps.employeeData) {
      this.setState({
        employeeData: this.props.employeeData,
      });
    }
    if (this.props.eventData !== prevProps.eventData) {

      if (this.props.eventData?.link != null) {
        this.setState({
          displayText: "Get My Affiliate Link",
        });
      } else {
        this.setState({
          displayText: "Earn 10% as an Affiliate",
        });
      }
    }
  }
  openAffiliateModal = () => {
    this.setState({ isAffiliateModalOpen: true });
  };

  closeAffiliateModal = () => {
    this.setState({ isAffiliateModalOpen: false });
  };
  render() {
    return (
      <>
        <div className="footer-portal">
          <Affiliates
            isOpen={this.state.isAffiliateModalOpen}
            onHide={this.closeAffiliateModal}
          />
          <div className="footer--menu">
            <div className="footer-zazafestival-menu">
              <Link to="#">
                <p className="portal-sponsers-text">My Watchlist</p>
              </Link>
              {AppConfig.API.APP_ID === 2836 ? (
                <>
                  {this.state.employeeData != null ? (
                    <Link to={"#"}>
                      <p
                        className="portal-sponsers-text"
                        onClick={this.openAffiliateModal}
                      >
                        {/* {this.state.eventData != null &&
                    this.state.eventData?.link != null
                      ? "Get Your Affiliates Link"
                      : "Become a Affiliates"} */}
                        {this.state.displayText}
                      </p>
                    </Link>
                  ) : (
                    <Link target="_blank" to="/authentication/my-account">
                      <p className="portal-sponsers-text">
                        Active Affiliate Account
                      </p>
                    </Link>
                  )}
                </>
              ) : (
                ""
              )}
              
              {AppConfig.API.APP_ID === 2836 ? (
                <Link
                  // to="/sponsorship"
                  target="_blank"
                  to={{
                    pathname: "https://contactus.ciright.com/",
                  }}
                >
                  <p className="portal-sponsers-text">Sponsorship</p>
                </Link>
              ) : (
                ""
              )}
              {AppConfig.API.APP_ID === 2836 ? (
                <Link
                  target="_blank"
                  to={{
                    pathname: "https://contactus.ciright.com/",
                  }}
                >
                  <p className="portal-sponsers-text">Contact Us</p>
                </Link>
              ) : (
                ""
              )}
              {AppConfig.API.APP_ID === 2836 ? (
                <Link to="/terms-of-use">
                  <p className="portal-sponsers-text">Terms & Conditions</p>
                </Link>
              ) : (
                <Link
                  target="_blank"
                  to={{
                    pathname: "https://veuit.com/terms-of-use",
                  }}
                >
                  <p className="portal-sponsers-text">Terms & Conditions</p>
                </Link>
              )}
            </div>
          </div>
          <div className="portalApp-footer-img">
            {AppConfig.API.APP_ID === 2836 ? (
              <Link to="/">
                <img
                  src={require("../../assets/images/CirightLive/ciright-live-logo.png")}
                  alt="ciright"
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  src={require("../../assets/images/veuit/TV-4.png")}
                  alt="ciright"
                  height="150px"
                  width="150px"
                />
              </Link>
            )}
            <div className="footer-iconsset">
              {/* <img
                src={require("../../assets/images/Portal/X.png")}
                alt="x-icon"
              />
              <Link
                target="_blank"
                to={{
                  pathname:
                    "https://www.linkedin.com/in/ciright-admin-906b8a238?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
                }}
              >
                <img
                  src={require("../../assets/images/Portal/linkedin.png")}
                  alt="linkedin"
                />
              </Link>
              <img
                src={require("../../assets/images/Portal/tiktoc.png")}
                alt="tiktoc"
              /> */}
              <Link
                target="_blank"
                to={{
                  pathname: "https://www.instagram.com/veuitofficial/",
                }}
              >
                <img
                  src={require("../../assets/images/Portal/instagram.png")}
                  alt="instagram"
                />
              </Link>
            </div>
          </div>
          <div className="portal-footer-last"></div>
        </div>
        {/* <div className="addciright-logo">
          <Link target="_blank" to={{ pathname: "https://www.ciright.com/" }}>
            <img
              src={require("../../assets/images/ciright.png")}
              alt="ciright"
            />
          </Link>
        </div> */}
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FooterManu));
