import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col } from "react-bootstrap";
import BrandMap from "./brand.component";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class BrandList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultMap: props.resultMap,
      tagName: props.tagName,
      id: props.id,
      viewAllUrl: props.viewAllUrl,
      prevId: "prev" + props.id,
      nextId: "next" + props.id,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="d-zazabgimg">
        <div className="main-content set-main-brand">
          <section id="iq-favorites">
            <Container fluid id="b-container">
              <Row id="d-space-show">
                <div className="d-flex align-items-center justify-content-between set-view-all-text">
                  <h3 className="main-title text-uppercase d-movie-title">
                    {this.state.tagName}
                  </h3>
                  {/* <Link className="iq-view-all d-view-text">VIEW ALL</Link> */}
                </div>
                <Col
                  sm="12"
                  className="overflow-hidden d-set-overflow set-col-brand"
                >
                  <div id="favorites-contens">
                    <div
                      id={this.state.prevId}
                      className="swiper-button swiper-button-prev b-set-prev"
                    >
                      <i className="fa fa-chevron-left d-icon"></i>
                    </div>
                    <div
                      id={this.state.nextId}
                      className="swiper-button swiper-button-next b-set-next"
                    >
                      <i className="fa fa-chevron-right d-icon"></i>
                    </div>
                    {/* <div className="brand-listslider"> */}
                    {/* <div className="set-staticbrand" id="set-staticbrand">
                      <img
                        src={require("../../assets/images/brand-img.png")}
                        alt="Congrats"
                        className="brand-image"
                      ></img>
                    </div> */}
                    <Swiper
                      navigation={{
                        prevEl: "#" + this.state.prevId,
                        nextEl: "#" + this.state.nextId,
                      }}
                      breakpoints={{
                        2560: {
                          slidesPerView: 9.9,
                          spaceBetween: 20,
                        },
                        2304: {
                          slidesPerView: 9.5,
                          spaceBetween: 15,
                        },
                        2150: {
                          slidesPerView: 8.5,
                          spaceBetween: 18,
                        },
                        2050: {
                          slidesPerView: 8,
                          spaceBetween: 20,
                        },
                        1800: {
                          slidesPerView: 8,
                          spaceBetween: 20,
                        },
                        1536: {
                          slidesPerView: 7,
                          spaceBetween: 20,
                        },
                        1440: {
                          slidesPerView: 6,
                          spaceBetween: 20,
                        },
                        1366: {
                          slidesPerView: 6,
                          spaceBetween: 20,
                        },
                        1280: {
                          slidesPerView: 6,
                          spaceBetween: 20,
                        },
                        1140: {
                          slidesPerView: 5.4,
                          spaceBetween: 20,
                        },
                        1054: {
                          slidesPerView: 5,
                          spaceBetween: 15,
                        },
                        992: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        991: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        767: {
                          slidesPerView: 3.8,
                          spaceBetween: 14,
                        },
                        577: {
                          slidesPerView: 3,
                          spaceBetween: 14,
                        },
                        576: {
                          slidesPerView: 3.4,
                          spaceBetween: 14,
                        },
                        494: {
                          slidesPerView: 3.5,
                          spaceBetween: 14,
                        },
                        460: {
                          slidesPerView: 3.5,
                          spaceBetween: 14,
                        },
                        430: {
                          slidesPerView: 3,
                          spaceBetween: 14,
                        },
                        414: {
                          slidesPerView: 2.9,
                          spaceBetween: 14,
                        },
                        375: {
                          slidesPerView: 2.5,
                          spaceBetween: 14,
                        },
                        340: {
                          slidesPerView: 2.2,
                          spaceBetween: 14,
                        },

                        320: {
                          slidesPerView: 2,
                          spaceBetween: 14,
                        },
                      }}
                      loop={false}
                      as="ul"
                      className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                    >
                      {this.state.resultMap.map((item, key) => {
                        return (
                          <SwiperSlide as="li" key={key} id="d-brand-slider">
                            <BrandMap resultObject={item} id={item.id} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    {/* <div className="set-staticbrand" id="set-staticbrand">
                      <img
                        src={require("../../assets/images/brand-img.png")}
                        alt="Congrats"
                        className="brand-image"
                      ></img>
                    </div> */}
                    {/* </div> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandList);
