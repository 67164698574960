import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import UserService from "../../services/user/user.service";
//import AddToAppleWallet from "./Add_to_Apple_Wallet.svg";
import Loader from "../../components/loader/loader";
import AppConfig from "../../config/app.config";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Ticket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      ticketCode: this.props.match.params.ticketCode,
      eventData: null,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    var ticketCode = this.props.match.params.ticketCode;
    if (ticketCode != null) {
      this.setState(
        {
          ticketCode: ticketCode,
          isLoading: 1,
        },
        () => {
          this.getTicketInfo();
        }
      );
    } else {
      NotificationManager.error("", "Invalid Url.", 3000);
      this.timeoutHandle = setTimeout(() => {
        this.setState({ isLoading: 0 });
        this.props.history.push("/");
      }, 2000);
    }
  }

  getTicketInfo = async () => {
    try {
      const res = await UserService.getApplePass(
        this.state.ticketCode,
        AppConfig.API.EVENT_ID
      );
      if (res.error != null) {
        if (
          res.error.response != null &&
          res.error.response.data != null &&
          res.error.response.data.message != null
        ) {
          NotificationManager.error("", res.error.response.data.message, 3000);
          this.timeoutHandle = setTimeout(() => {
            this.setState({ isLoading: 0 });
            this.props.history.push("/");
          }, 2000);
        } else {
          NotificationManager.error(
            "",
            "Could not get Apple Wallet Pass.",
            3000
          );
        }
        this.timeoutHandle = setTimeout(() => {
          this.setState({ isLoading: 0 });
          this.props.history.push("/");
        }, 2000);
      } else {
        if (res["status"]) {
          const data = res["data"];
          if (data === null) {
            NotificationManager.error("", "Invalid Ticket Code.", 3000);
            this.timeoutHandle = setTimeout(() => {
              this.setState({ isLoading: 0 });
              this.props.history.push("/");
            }, 2000);
          } else {
            this.setState({ eventData: res["data"] }, () => {
              this.getWalletPass();
            });
          }
        } else {
          NotificationManager.error(
            "",
            "Could not get Apple Wallet Pass.",
            3000
          );
          this.timeoutHandle = setTimeout(() => {
            this.setState({ isLoading: 0 });
            this.props.history.push("/");
          }, 2000);
        }
      }
    } catch (err) {
      NotificationManager.error("", "Could not get Apple Wallet Pass.", 3000);
      this.timeoutHandle = setTimeout(() => {
        this.setState({ isLoading: 0 });
        this.props.history.push("/");
      }, 2000);
    }
  };

  getWalletPass = () => {
    try {
      var bytes = new Uint8Array(JSON.parse(this.state.eventData.buffer));
      var blob = new Blob([bytes], { type: "application/vnd.apple.pkpass" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Event.pkpass";
      link.click();
      window.close();
      // this.timeoutHandle = setTimeout(() => {
      //   this.setState({ isLoading: 0 });
      //   this.props.history.push("/");
      // }, 2000);
    } catch (err) {
      NotificationManager.error("", "Could not get Apple Wallet Pass.", 3000);
      this.timeoutHandle = setTimeout(() => {
        this.setState({ isLoading: 0 });
        this.props.history.push("/");
      }, 2000);
    }
  };

  render() {
    return this.state.isLoading === 1 ? (
      <Loader showLogo></Loader>
    ) : (

      <div></div>
      // <div className="buy-ticket">
      //   <div
      //     className="row"
      //     style={{
      //       justifyContent: "center",
      //       alignItems: "center",
      //       width: "100%",
      //       margin: 0,
      //     }}
      //     id="set-spacebar"
      //   >
      //     {this.state.eventData ? (
      //       <>
      //         <img
      //           style={{
      //             height: "60px",
      //             textAlign: "center",
      //             margin: "30px",
      //           }}
      //           onClick={this.getWalletPass}
      //           src={AddToAppleWallet}
      //           alt="Add to Apple Wallet"
      //         />
      //         {/* <iframe
      //               title="Ticket"
      //               style={{ width: "100%", height: "1200px", border: "none" }}
      //               srcDoc={this.state.eventData.ticketContent}
      //             ></iframe> */}
      //       </>
      //     ) : (
      //       this.state.isLoading === 0 && (
      //         <p style={{ textAlign: "center" }}>Invalid Ticket Id.</p>
      //       )
      //     )}
      //   </div>
      // </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
