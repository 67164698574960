import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import AppConfig from "../../config/app.config";

const Order = ({ open, onClose }) => {
  return (
    <div>
      <Modal
        show={open}
        centered
        contentClassName="bg-transparent"
        id="home-ordermodal"
      >
        <section className="order-mainsection" style={{ position: "relative" }}>
          <img
            src={require("../../assets/images/close x.png")}
            alt="not found"
            style={{
              position: "absolute",
              right: "0.5rem",
              top: "0.5rem",
              aspectRatio: "1",
              height: "2rem",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
          <h3 className="order-concert">Exclusive Pop-up Concert</h3>
          {AppConfig.API.IS_ANNOUNCEMENT !== 0 ? (
            <h2 className="concert-name">CIAN DUCROT</h2>
          ) : (
            <p className="concert-name">MJ</p>
          )}
          <p className="cocert-data">Streaming Live from Las Vegas</p>

          <div className="live-concert">
            <div style={{ textAlign: "start" }}>
              <p className="live-stream">View Live Stream</p>
              {/* <p className="concert-replay">(Includes Concert Replay)</p> */}
            </div>
            <p className="order-stream">
              {JSON.parse(localStorage.getItem("eventData"))?.eventPrice ??
                "$9.99"}
            </p>
          </div>
          <p className="watch-later">Watch now or replay later!</p>
          <Link to="/checkout-ticket">
            <button className="ordernow-btn">ORDER NOW</button>
          </Link>
        </section>
      </Modal>
    </div>
  );
};

export default Order;
