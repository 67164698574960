import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AppConfig from "../../config/app.config"
import {
  selectBrands,
  selectemployeeData,
  selectMuxUrl,
  selecteventData
} from "../../store/home/home.selectors"
import {
  brandsSyncStart,
  setSigninRedirectPath,
  setMuxUrl,
  setEventData
} from "../../store/home/home.actions"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Loader from "../../components/loader/loader"
import CustomSwiper from "../../components/custom-swiper/swiper.component"
import StreamIframe from "../../components/iframe/StreamIframe"
import "./home.css"
import CountDown from "../../components/countdown/Countdown"
import Order from "../../components/model/Order"
import moment from "moment"
import { Link } from "react-router-dom"
import UserService from "../../services/user/user.service"
import BrandList from "../../components/brand-list/brand-list.component"
import SliderStaticData from "../../components/slider-data.json"

const mapStateToProps = (state) => {
  return {
    selectBrands: selectBrands(state),
    employeeData: selectemployeeData(state),
    muxUrl: selectMuxUrl(state),
    eventData: selecteventData(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setSigninRedirectPath,
      brandsSyncStart,
      setMuxUrl,
      setEventData
    },
    dispatch
  ),
})

export class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: 1,
      brandsMap: [],
      checkingUpdate: false,
      isLogin: false,
      isShowModel: false,
      modelUrl: "",
      showTicketsSold: false,
      isNotificationShow: false,
      notificationShowMessageType: "",
      notificationShowMessage: "",
      // timeLeft: this.calculateTimeLeft(),
      intervalId: null,
      isStreamStarted: false,
      eventdate: "08/29/2024 23:00:00",
      eventName: "Ciright Live Stream",
      eventId: -1,
      eventDescription: "",
      eventImage: "",
      orderModel: false,
      orderId: null,
      convenienceFee: 0,
      price: 9.99,
      search: "",
      linkForIframe: "https://portals.ciright.com/ps/CirightLive1",
      muxPlayBackId: ""
    }
  }

  async componentDidMount() {
    this.setState({
      search:
        window.location?.search != null && window.location?.search !== ""
          ? window.location?.search
          : "",
    })
    window.scrollTo(0, 0)

    const employeeId = this.props.employeeData?.employeeId ?? -1;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    UserService.getEventData({
      eventId: AppConfig.API.EVENT_ID,
      appId: AppConfig.API.APP_ID,
      employeeId: employeeId,
      timeZone: timeZone,
      appId: AppConfig.API.APP_ID,
    }).then((res) => {
      if (res.error != null) {
      } else if (res.status) {
        localStorage.setItem("eventData", JSON.stringify(res.data));
        this.props.setEventData({ eventData: res.data });
      }
    });

    const { brandsSyncStart, selectBrands, muxUrl, setMuxUrl } = this.props
    // console.log(this.props.eventData?.orderId)
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: this.props.employeeData?.employeeId,
      loginEmployeeId: this.props.employeeData?.employeeId,
      appId: AppConfig.API.APP_ID,
      timeZone: null,
    }
    this.props.setSigninRedirectPath({ signinRefirectPath: null })
    if (this.props.employeeData?.employeeId != null) {
      await this.setState({ isLogin: true })
    } else {
      await this.setState({ isLogin: false })
    }

    if (!muxUrl) {
      const res = await UserService.getMuxUrl({
        subscriptionId: AppConfig.API.MUX_SUBSCRIPTION_ID,
        verticalId: 2453,
        appId: 2601,
      })
      // console.log(muxUrl)
      if (res.error == null && res.status) {
        setMuxUrl({ muxUrl: res?.data?.playbackId })
        this.setState({ muxPlayBackId: res?.data?.playbackId })
      }
    } else {
      this.setState({ muxPlayBackId: muxUrl })
    }

    if (selectBrands.initialSynced && selectBrands.brandsList !== null) {
      await this.setState({ brandsMap: selectBrands.brandsList })
    } else {
      await brandsSyncStart(payload)
    }

    if (localStorage.getItem("eventData") != null) {
      const eventData = await JSON.parse(localStorage.getItem("eventData"))
      const webUrl =
        eventData.webUrl != null && eventData.webUrl !== ""
          ? eventData.webUrl
          : ""
      this.setState({
        eventId: eventData.eventId,
        eventName: eventData.eventName,
        eventdate: eventData.eventdate,
        eventDescription: eventData.projectDescription,
        linkForIframe: "https://portals.ciright.com/ps/" + webUrl,
        eventImage: eventData.eventImage,
        convenienceFee:
          eventData.convenienceFee != null && !isNaN(eventData.convenienceFee)
            ? eventData.convenienceFee
            : 0,
        price:
          eventData.price != null && !isNaN(eventData.price)
            ? eventData.price
            : 9.99,
        isLoading: 0,
        orderId: eventData.orderId != null ? eventData.orderId : null,
        isStreamStarted: this.isStreamComplete(
          eventData.eventdate ?? "08/29/2024 23:00:00"
        ),
      })
    } else {
      this.setState({ isLoading: 0 })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectBrands } = this.props

    if (selectBrands.initialSynced) {
      if (prevProps.selectBrands.brandsList !== selectBrands.brandsList) {
        this.setState({ brandsMap: selectBrands.brandsList })
      }
    }

    if (
      this.state.isStreamStarted &&
      !this.doesCookieExist("isPreviewComplete")
    ) {
      const timeOut = setTimeout(() => {
        this.setState({ orderModel: true })
        const date = new Date()
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000)
        const expires = "expires=" + date.toUTCString()

        document.cookie = `isPreviewComplete=1; ${expires}; path=/`
        clearTimeout(timeOut)
      }, 60 * 1000)
    }
  }

  doesCookieExist(name) {
    const nameEQ = `${name}=`
    const cookies = document.cookie.split(";")

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim()
      if (cookie.indexOf(nameEQ) === 0) {
        return true
      }
    }
    return false
  }

  closeNotification = () => {
    this.setState({
      isNotificationShow: false,
      notificationShowMessageType: "",
      notificationShowMessage: "",
    })
  }

  isStreamComplete(targetDate) {
    // const now = new Date(
    //   new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
    // )
    const now = new Date()
    const endDate = moment(targetDate, "MM/DD/YYYY hh:mm:ss").toDate()
    const diff = endDate - now
    return diff <= 0
  }

  render() {
    return (
      <div className="">
        {this.state.orderId == null ? (
          <Order
            open={this.state.orderModel}
            onClose={() => {
              this.setState({ orderModel: false })
            }}
          />
        ) : (
          ""
        )}
        {this.state.isLoading === 1 ? (
          <Loader showLogo />
        ) : (
          <>
            {this.state.isLoading === 2 ? <Loader /> : ""}
            {AppConfig.API.IS_ANNOUNCEMENT !== 2 &&
              !this.state.isStreamStarted ? (
              <>
                <CustomSwiper />
                <div className="event-name-banner">
                  {AppConfig.API.APP_ID === 2836 && (
                    <p className="event-banner-text">
                      {/* {this.state.eventName} */}
                      Secret <span>Pop-Up Show </span> – LIVE Streaming{" "}
                      <span> from Dublin Ireland </span>
                    </p>
                  )}
                  {AppConfig.API.APP_ID === 2838 && (
                    <p className="event-banner-text">
                      {/* {this.state.eventName} */}
                      <span>MJ</span> The <span>Evolution</span> Tribute Show
                    </p>
                  )}
                </div>
                <CountDown
                  time={
                    this.state.search !== ""
                      ? "08/28/2024 16:00:00"
                      : this.state.eventdate
                  }
                  //time={"08-23-24 11:31:00"}
                  orderId={this.state.orderId}
                  price={this.state.price}
                  callback={() => this.setState({ isStreamStarted: true })}
                />
              </>
            ) : (
              <>
                {AppConfig.API.IS_ANNOUNCEMENT === 2 ? (
                  <>
                    <CustomSwiper />
                    <div className="event-name-banner">
                      {AppConfig.API.APP_ID === 2836 && (
                        <p className="event-banner-text">
                          {/* {this.state.eventName} */}
                          Secret <span>Pop-Up Show </span> – Recorded LIVE{" "}
                          <span> from O'Connell Street,Dublin Ireland </span>
                        </p>
                      )}
                    </div>
                    <div className="countdown-container">
                      <h3
                        className="countdown-info"
                        style={{ color: "#fff", fontSize: "36px" }}
                      >
                        Miss the Show?
                      </h3>

                      <div className="countdown-order">
                        <Link
                          // to="/buy-ticket"
                          to="#"
                          style={{
                            width: "min-content",
                            display: "block",
                            marginInline: "auto",
                          }}
                        >
                          <button
                            className="countdown-btn"
                            style={{ cursor: "pointer" }}
                            disabled={true}
                          >
                            Buy it Now $
                            {this.state.price != null && this.state.price >= 0
                              ? this.state.price
                              : 9.99}
                          </button>
                        </Link>
                        {/* <p className="count-replay">(Concert Replay)</p> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <StreamIframe
                    hide={
                      this.doesCookieExist("isPreviewComplete") ||
                      this.state.orderModel
                    }
                    link={this.state.muxPlayBackId}
                    orderId={this.props.eventData?.orderId}
                    handleClick={() => this.setState({ orderModel: true })}
                  />
                )}
              </>
            )}
            <div className="portals-network">
              {/* <div className="portals-network-container">
                <div className="mobile-brand" style={{ alignItems: "end" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        fontFamily: "Avenir Next",
                        fontSize: "18px",
                        Color: "#fff",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      For the benefit of:
                    </p>
                    <img
                      src={require("../../assets/images/CirightLive/minding-creative.png")}
                      alt="ciright"
                      className="brand-list-1"
                      style={{ marginRight: "1rem", marginBottom: "1rem" }}
                    />
                  </div>
                  <img
                    src={require("../../assets/images/CirightLive/barnardos.png")}
                    alt="ciright"
                    className="brand-list-2"
                    style={{ marginRight: "1rem", marginBottom: "1rem" }}
                  />
                </div>
              </div> */}
              <div className="portal-brandlist">
                <BrandList
                  resultMap={SliderStaticData.FAN_CLUB}
                  id={8}
                  tagName="Fan Club"
                  viewAllUrl="/view-all/brands"
                />
              </div>
              <div className="portal-brandlist">
                <BrandList
                  resultMap={SliderStaticData.AFFILIATES}
                  id={9}
                  tagName="Affiliates"
                  viewAllUrl="/view-all/brands"
                />
              </div>
              <div className="portal-brandlist">
                <BrandList
                  resultMap={SliderStaticData.CAST_1}
                  id={10}
                  tagName="Cast"
                  viewAllUrl="/view-all/brands"
                />
              </div>
              <div className="portal-brandlist">
                <BrandList
                  resultMap={SliderStaticData.CAST_2}
                  id={11}
                  tagName="Cast"
                  viewAllUrl="/view-all/brands"
                />
              </div>

              <div className="portals-network-container">
                <div className="mobile-portallogo">
                  {/* <APPLOGOWHITE /> */}
                </div>

                {/* <div className="portal-description">
                  <p>THE PORTALS NETWORK</p> 
                  <p>
                    Portals are technology art sculptures. They connect to a
                    network of identical sculptures all over the planet. Each
                    Portal is located in the public space, providing a
                    real-time, unfiltered livestream 24/7 that is frequently
                    rotating between different Portal locations on Earth.
                  </p>
                </div>

                <div className="types-ofportals">
                  <img
                    src={require("../../assets/images/Portal/new-york.png")}
                    alt="portal-img"
                  />
                  <img
                    src={require("../../assets/images/Portal/poland.png")}
                    alt="portal-img"
                  />
                </div>
                <div className="types-ofportals">
                  <img
                    src={require("../../assets/images/Portal/lithuania.png")}
                    alt="portal-img"
                  />
                  <img
                    src={require("../../assets/images/Portal/dublin.png")}
                    alt="portal-img"
                  />
                </div> */}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
