import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { selectemployeeData } from "../../store/home/home.selectors";
import { NotificationManager } from "react-notifications";
import Loader from "../../components/loader/loader";
import ProfileComponent from "../../components/profile/profile.component";
import { setSigninRedirectPath } from "../../store/home/home.actions";

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setSigninRedirectPath }, dispatch),
});

export class AccountInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      orders: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeData?.employeeId != null) {
      this.getOrders();
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: "/account-info",
      });
      this.props.history.push("/authentication/my-account");
    }
  }

  getOrders = async () => {
    try {
      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        appId: AppConfig.API.APP_ID,
        loginEmployeeId: this.props.employeeData?.employeeId,
      };
      const res = await UserService.getAllTickets(payload);
      if (res.error != null) {
        if (
          res.error.response != null &&
          res.error.response.data != null &&
          res.error.response.data.message != null
        ) {
          NotificationManager.error("", res.error.response.data.message, 3000);
        } else if (res.error.message != null) {
          NotificationManager.error("", "Could not get Ticket details.", 3000);
        }
        this.setState({ isLoading: 0 });
      } else {
        if (res["status"]) {
          var data = res["data"];
          this.setState({ orders: data, isLoading: 0 });
        } else {
          NotificationManager.error("", "Could not get Ticket details.", 3000);
          this.setState({ isLoading: 0 });
        }
      }
    } catch (err) {
      this.setState({ isLoading: 0 });
    }
  };

  render() {
    return (
      <div className="todays-portal-home">
        {this.state.isLoading === 1 ? (
          <Loader showLogo />
        ) : this.props.employeeData != null && this.state.orders != null ? (
          <div className="portal-accountinfo" id="account-info-tabs">
            <ProfileComponent />
            {/* <AddCard /> */}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
