import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectemployeeData } from "../../store/home/home.selectors";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import Loader from "../../components/loader/loader";
import { ReactComponent as Failure } from "../../assets/images/Failure.svg";
import { ReactComponent as Ongoing } from "../../assets/images/Ongoing.svg";
import { ReactComponent as Somthingwrong } from "../../assets/images/somthingwrong.svg";
import Modal from "react-bootstrap/Modal";
import "../../components/order-confirmation/orderConfirmation.css";
import { ReactComponent as CloseBlackIcon } from "../../assets/images/close_icon_black.svg";
import "../../components/order-confirmation/orderConfirmation.css";
const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeEmail: null,
      orderId: null,
      qtySum: null,
      eventData: null,
      subTotal: 0,
      convenienceFee: 0,
      taxPercent: 0,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    var employeeData = this.props.employeeData;
    if (employeeData?.email != null) {
      this.setState({
        employeeEmail: employeeData.email,
      });

      //await this.getTaxPercent();

      if (localStorage.getItem("eventData") != null) {
        const eventData = await JSON.parse(localStorage.getItem("eventData"));

        await this.setState({
          eventData: eventData,
          subTotal:
            eventData.price != null && !isNaN(eventData.price)
              ? eventData.price
              : 9.99,
          convenienceFee:
            eventData.convenienceFee != null && !isNaN(eventData.convenienceFee)
              ? eventData.convenienceFee
              : 0,
        });
      }

      this.validateTimeOut = setTimeout(() => {
        this.validatePayment();
      }, 5000);
    } else {
      this.props.history.push("/");
    }
  }

  componentWillUnmount() {
    if (this.validateTimeOut != null) {
      clearTimeout(this.validateTimeOut);
    }
  }

  async componentDidUpdate(prevProps, prevState) {}

  getTaxPercent = async () => {
    try {
      const res = await UserService.getTaxByZipcode(AppConfig.API.APP_ZIPCODE);
      if (res.error != null) {
        console.log(res.error?.response?.data?.message);
      } else {
        if (res["status"]) {
          this.setState({ taxPercent: res["data"][0].taxPercent });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  validatePayment = () => {
    this.setState({ isLoading: 1 });
    const orderId = parseInt(window.location.pathname.split("/")[2]);
    this.setState({ orderId: orderId });
    const payload = {
      orderId,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
    };
    UserService.getPaymentStatus(payload)
      .then(async (res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0, paymentStatus: 3 });
        } else {
          if (res["status"]) {
            if (res["data"] != null && res["data"].orderStatus !== null) {
              const orderStatus = res["data"].orderStatus;
              const orderError = res["data"].orderError;
              var paymentStatus = 3;
              if (orderStatus === "PAYMENT_COMPLETE") {
                paymentStatus = 0;
                //NotificationManager.success("Transaction Success", "", 2000);
              }
              if (orderStatus === "PAYMENT_FAIL") {
                paymentStatus = 1;
                //NotificationManager.error("Transaction Failed", "", 2000);
              }
              if (orderStatus === "PAYMENT_PROCESS") {
                paymentStatus = 2;
                //NotificationManager.success("Transaction Ongoing", "", 2000);
              }
              //const equipments = res["data"].equipments;
              let qtySum = 0;

              // if (equipments !== null) {
              //   qtySum = equipments.reduce(
              //     (sum, equipment) => sum + equipment.qty,
              //     0
              //   );
              // }
              this.setState({
                isLoading: 0,
                paymentStatus,
                paymentError: orderError,
                qtySum,
              });
            }
          } else {
            //NotificationManager.error(res["message"], "", 2000);
            this.setState({ isLoading: 0, paymentStatus: 3 });
          }
        }
      })
      .catch((err) => {
        //NotificationManager.error(err, "", 2000);
        this.setState({ isLoading: 0, paymentStatus: 3 });
      });
  };

  onClose = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <>
        {this.state.isLoading === 1 ? (
          <Loader showLogo />
        ) : (
          <div
            className="todays-portal-home"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              className="portal-confirmation"
              // style={{
              //   display: "flex",
              //   flexDirection: "column",
              //   flex: 1,
              // }}
              style={{
                display: "flex",
                // flexDirection: "column",
                flex: 1,
                backgroundColor: "#000",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Modal
                show={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="order-confiramtion-modal"
              >
                <div className="order-confiramtion-modal">
                  <CloseBlackIcon
                    className="close-icon icon-btn"
                    onClick={() => this.onClose()}
                  />
                  <div className="order-title">
                    <p className="order-title-text">Order Confirmation</p>
                  </div>
                  <hr className="top-hr" />
                  {this.state.paymentStatus === 0 ? (
                    <>
                      <p className="stream-text">STREAM NOW</p>
                      <p className="link-text">
                        {/* www.portals.live.com/236136wey87d6yw */}
                        www.live.veuit.com
                      </p>
                    </>
                  ) : this.state.paymentStatus === 1 ? (
                    <>
                      <Failure className="payment-img" />

                      <p className="artist-name">Failure</p>
                      <p className="concert-location">
                        Oops! Your transaction was unsuccessful. Please try
                        again or contact our customer support for assistance.
                      </p>
                    </>
                  ) : this.state.paymentStatus === 2 ? (
                    <>
                      <Ongoing className="payment-img" />

                      <p className="artist-name">Ongoing</p>
                      <p className="concert-location">
                        Your transaction is in progress. Please wait while we
                        process your payment. Thank you for your patience.
                      </p>
                    </>
                  ) : (
                    <>
                      <Somthingwrong className="payment-img" />

                      <p className="artist-name">Oops!</p>
                      <p className="concert-location">
                        Something went wrong couldn't get your payment status.
                      </p>
                    </>
                  )}
                  {this.state.eventData.eventImage != null &&
                  this.state.eventData.eventImage !== "" ? (
                    <img
                      className="order-image"
                      src={this.state.eventData.eventImage}
                      alt="Event-img"
                    />
                  ) : (
                    ""
                  )}
                  <div
                    className="artist-info"
                    style={{
                      textAlign: "center",
                      height: "99px",
                      marginTop: "18px",
                    }}
                  >
                    {AppConfig.API.IS_ANNOUNCEMENT !== 0 ? (
                      <p className="artist-name">MJ</p>
                    ) : (
                      <p className="artist-name">MJ</p>
                    )}

                    <p className="concert-location">Live From Las Vegas</p>
                    <p className="concert-location">
                      Live Stream Ticket{" "}
                      {/* <p
                        style={{
                          fontWeight: 200,
                          display: "inline",
                        }}
                      >
                        (includes Replay)
                      </p> */}
                    </p>
                  </div>
                  <div className="order-promo">
                    <div className="order-subtotal">
                      <h1>Subtotal</h1>
                      <h2>${this.state.subTotal.toFixed(2)}</h2>
                    </div>
                    <div className="order-subtotal">
                      <h1>Processing Fees</h1>
                      <h2>${this.state.convenienceFee.toFixed(2)} </h2>
                    </div>
                    <div className="order-subtotal">
                      <h1>Tax</h1>

                      <h2>
                        $
                        {(
                          ((this.state.subTotal + this.state.convenienceFee) *
                            this.state.taxPercent) /
                          100
                        ).toFixed(2)}
                      </h2>
                    </div>
                    <hr className="order-line" />
                    <div className="order-total">
                      <h1>Total</h1>
                      <h2>
                        $
                        {(
                          this.state.subTotal +
                          this.state.convenienceFee +
                          ((this.state.subTotal + this.state.convenienceFee) *
                            this.state.taxPercent) /
                            100
                        ).toFixed(2)}
                      </h2>
                    </div>
                  </div>
                </div>
              </Modal>

              {/* {this.state.paymentStatus === 0 ? (
                <div className="app-confirmation">
                  <p>
                    <span className="locked-in">Locked in. </span> You’re one
                    step closer to the most anticipated event of the summer
                  </p>
                  <h3>We’ve sent a reciept to {this.state.employeeEmail}</h3>

                  <h4>
                    Order ID: <span>{this.state.orderId}</span>
                  </h4>
                  {this.state.qtySum !== 0 && this.state.qtySum != null ? (
                    <h2>
                      Portal
                      <br></br>
                      <br></br>
                      Tickets: {this.state.qtySum}
                    </h2>
                  ) : (
                    ""
                  )}
                  <p className="myaccount">
                    You can retrieve your tickets by logging into your Portal
                    “My Account” page.
                  </p>

                  <h6>
                    Portal <br></br>
                    MAY 25th - 26th, 2024 <br></br>
                    Bader Field, Atlantic City NJ <br></br>
                  </h6>
                  <Link
                    target="_blank"
                    to={{ pathname: "https://goo.gl/maps/85XA4WzBDSsGtEaq8" }}
                  >
                    <img
                      src={require("../../assets/images/location-icon.png")}
                      className="location-icon-confirm"
                      alt="Location Pin"
                    ></img>
                  </Link>
                  <hr className="confirm-border"></hr>
                </div>
              ) : this.state.paymentStatus === 1 ? (
                <div className="app-confirmation">
                  <Failure className="payment-img" />
                  <p>
                    Failure <br></br>
                    Oops! Your transaction was unsuccessful. Please try again or
                    contact our customer support for assistance.
                  </p>
                </div>
              ) : this.state.paymentStatus === 2 ? (
                <div className="app-confirmation">
                  <Ongoing className="payment-img" />
                  <p>
                    Ongoing <br></br>
                    Your transaction is in progress. Please wait while we
                    process your payment. Thank you for your patience.
                  </p>
                </div>
              ) : (
                <div className="app-confirmation">
                  <Somthingwrong className="payment-img" />
                  <p>
                    Oops! <br></br>
                    Something went wrong couldn't get your payment status.
                  </p>
                </div>
              )} */}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
