import HomeActionTypes from "./home.types"
// Set the reducers initial state.
const INITIAL_STATE = {
  initialSynced: false,
  message: null,
  location: null,
  signinRedirectPath: null,
  masterEvent: null,
  cart: {},
  employeeData: null,
  muxUrl: null,
}

// Define scenarios for reducers.
const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HomeActionTypes.SET_LOCATION:
      return {
        ...state,
        location: action.payload.location,
      }
    case HomeActionTypes.SET_SIGNIN_REDIRECT_PATH:
      return {
        ...state,
        signinRedirectPath: action.payload.signinRedirectPath,
      }
    case HomeActionTypes.BRANDS_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      }
    case HomeActionTypes.BRANDS_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        brandsList: action.payload.brandsList,
        message: action.payload.message,
      }
    case HomeActionTypes.UPDATE_MASTER_EVENT:
      return {
        ...state,
        masterEvent: { ...action.payload.masterEvent },
      }
    case HomeActionTypes.UPDATE_CART:
      return {
        ...state,
        cart: { ...action.payload.cart },
      }
    case HomeActionTypes.SET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: action.payload.employeeData,
      }
    case HomeActionTypes.SET_EVENT_DATA:
      return {
        ...state,
        eventData: action.payload.eventData,
      }
    case HomeActionTypes.SET_MUX_URL:
      return {
        ...state,
        muxUrl: action.payload.muxUrl,
      }
    default:
      return state
  }
}

export default homeReducer
