import React from "react";
import { connect } from "react-redux";
// Partials
import HeaderManu from "../components/header/header-manu";
import FooterManu from "../components/footer/footer-manu";

// Router Component
import MainRoute from "../router/main-route";
import { BrowserRouter as Router } from "react-router-dom";
import { selectLocation } from "../store/home/home.selectors";
import { bindActionCreators } from "redux";
import UserService from "../services/user/user.service";
import AppConfig from "../config/app.config";
import { NotificationManager } from "react-notifications";
import { updateMasterEvent } from "../store/home/home.actions";
import Loader from "../components/loader/loader";
import {
  selectemployeeData,
  selecteventData,
} from "../store/home/home.selectors";
import { setEventData, setMuxUrl } from "../store/home/home.actions";

const mapStateToProps = (state) => {
  return {
    selectlocation: selectLocation(state),
    employeeData: selectemployeeData(state),
    eventData: selecteventData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateMasterEvent,
      setEventData,
    },
    dispatch
  ),
});

export class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: null,
      isLoading: 1,
    };
  }

  async componentDidMount() {
    if (
      this.props.selectlocation !== null &&
      this.props.selectlocation.location !== null &&
      this.props.selectlocation.location.pathname !== null
    ) {
      this.setState({ path: this.props.selectlocation.location.pathname });
    }
    // var backToTop = document.getElementById("back-to-top");
    // if (backToTop != null) {
    //   window.addEventListener("scroll", (e) => {
    //     var backToTop = document.getElementById("back-to-top");
    //     if (backToTop != null) {
    //       if (document.documentElement.scrollTop > 50) {
    //         backToTop.classList.add("show");
    //       } else {
    //         backToTop.classList.remove("show");
    //       }
    //     }
    //   });
    //   document.querySelector("#top")?.addEventListener("click", (e) => {
    //     e.preventDefault();
    //     window.scrollTo({ top: 0, behavior: "smooth" });
    //   });
    // }

    const res = await UserService.getMuxUrl({
      subscriptionId: AppConfig.API.MUX_SUBSCRIPTION_ID,
      verticalId: 2453,
      appId: 2601,
    })

    if (res.error == null && res.status) {
      setMuxUrl({ muxUrl: res?.data?.playbackId })
    }
    await this.getEventListFromMasterEventId();
  }

  async componentDidUpdate(prevState, prevProps) {
    if (
      this.props.selectlocation.location != null &&
      this.props.selectlocation.location.pathname !== this.state.path
    ) {
      this.setState({ path: this.props.selectlocation.location.pathname });
    }
  }

  getEventListFromMasterEventId = async () => {
    try {
      const employeeId = this.props.employeeData?.employeeId ?? -1;
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      UserService.getEventData({
        eventId: AppConfig.API.EVENT_ID,
        appId: AppConfig.API.APP_ID,
        employeeId: employeeId,
        timeZone: timeZone,
      }).then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 });
          //NotificationManager.warning("Something Went Wrong!!", "", 2000);
        } else if (res.status) {
          localStorage.setItem("eventData", JSON.stringify(res.data));
          this.props.setEventData({ eventData: res.data });
          this.setState({ isLoading: 0 });
        } else {
          this.setState({ isLoading: 0 });
        }
      });
    } catch (err) {
      NotificationManager.error(err, "", 2000);
    }
  };

  render() {
    return (
      <div>
        <div className="appbgimage-opacity">
          <div className="todays-portal-home">
            <div
              style={{
                display: "flex",
                minHeight: "100vh",
                flexDirection: "column",
                flex: 1,
              }}
              className="todays-portal-dashboard"
            >
              <HeaderManu path={this.state.path} />
              {this.state.isLoading === 1 ? (
                <Loader showLogo />
              ) : (
                <>
                  {AppConfig.API.BASE_PATH != null ? (
                    <Router>
                      <MainRoute />
                    </Router>
                  ) : (
                    <MainRoute />
                  )}
                </>
              )}
            </div>
            <FooterManu />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
