import { Switch, Route } from "react-router-dom";
import MainLayout from "../layouts/main-layout";
import AuthenticationLayout from "../layouts/authentication-layout";
import { setLocation, setEmployeeData } from "../store/home/home.actions";

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setLocation,
      setEmployeeData,
    },
    dispatch
  ),
});

class LayoutsRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
      isLoading: 1,
    };
    this.props.setLocation({ location: this.props.location });
  }

  componentDidMount() {
    var employeeData = localStorage.getItem("employeeData");
    if (employeeData != null && employeeData !== "") {
      employeeData = JSON.parse(employeeData);
    }
    if (employeeData != null) {
      this.props.setEmployeeData({ employeeData: employeeData });
    }
    this.setState({ isLoading: 0 });
  }

  componentDidUpdate() {
    if (this.state.location !== this.props.location) {
      this.props.setLocation({ location: this.props.location });
      this.setState({ location: this.props.location });
    }
  }

  render() {
    return this.state.isLoading === 0 ? (
      <Switch location={this.props.location}>
        <Route path="/authentication" component={AuthenticationLayout} />
        <Route path="/" component={MainLayout} />
      </Switch>
    ) : (
      ""
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LayoutsRoute));
