import React from "react";
import { connect } from "react-redux";
import { selectemployeeData } from "../../store/home/home.selectors";
import { bindActionCreators } from "redux";
import { setEmployeeData } from "../../store/home/home.actions";
import './watchlist.css';

const mapStateToProps = (state) => {
    return {
          employeeData: selectemployeeData(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ setEmployeeData }, dispatch),
});

export class Watchlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <div className="portals-network">
                    <div className="watchlist-main">
                        <p className="watchlist-title-text">PURCHASED</p>
                        {/* <img src={require('../../assets/images/veuit/cian-ducrot.png')} /> */}
                        <p className="watchlist-subtitle-text">Cian Ducrot - Live from O’Connell Street – Dublin Ireland</p>
                    </div>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Watchlist)
