const HomeActionTypes = {
  SET_LOCATION: "SET_LOCATION",
  SET_SIGNIN_REDIRECT_PATH: "SET_SIGNIN_REDIRECT_PATH",
  BRANDS_SYNC_START: "BRANDS_SYNC_START",
  BRANDS_SYNC_FINISH: "BRANDS_SYNC_FINISH",
  UPDATE_CART: "UPDATE_CART",
  UPDATE_MASTER_EVENT: "UPDATE_MASTER_EVENT",
  SET_EMPLOYEE_DATA: "SET_EMPLOYEE_DATA",
  SET_EVENT_DATA: "SET_EVENT_DATA",
  SET_MUX_URL: "SET_MUX_URL",
}

export default HomeActionTypes
