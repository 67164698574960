import HomeActionTypes from "./home.types"

export const setLocation = (payload) => ({
  type: HomeActionTypes.SET_LOCATION,
  payload,
})

export const setSigninRedirectPath = (payload) => ({
  type: HomeActionTypes.SET_SIGNIN_REDIRECT_PATH,
  payload,
})

export const updateMasterEvent = (payload) => ({
  type: HomeActionTypes.UPDATE_MASTER_EVENT,
  payload,
})

export const brandsSyncStart = (payload) => ({
  type: HomeActionTypes.BRANDS_SYNC_START,
  payload,
})

export const brandsSyncFinish = (payload) => ({
  type: HomeActionTypes.BRANDS_SYNC_FINISH,
  payload,
})

export const updateCart = (payload) => ({
  type: HomeActionTypes.UPDATE_CART,
  payload,
})

export const setEmployeeData = (payload) => ({
  type: HomeActionTypes.SET_EMPLOYEE_DATA,
  payload,
})

export const setEventData = (payload) => ({
  type: HomeActionTypes.SET_EVENT_DATA,
  payload,
})
export const setMuxUrl = (payload) => ({
  type: HomeActionTypes.SET_MUX_URL,
  payload,
})
